@import "../colors";

.App {
}

.rounded-right-sidebar {
  border-radius: 6px;
  background-color: #fff;
  padding: 15px;
}

.rounded-content-body {
  border-radius: 6px;
  background-color: #fff;
  padding: 24px;
  //padding: 1rem;
  //box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.b-example-divider {
  width: 100%;
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  vertical-align: -.125em;
  fill: currentColor;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.btn-bd-primary {
  --bd-violet-bg: #712cf9;
  --bd-violet-rgb: 112.520718, 44.062154, 249.437846;

  --bs-btn-font-weight: 600;
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: var(--bd-violet-bg);
  --bs-btn-border-color: var(--bd-violet-bg);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: #6528e0;
  --bs-btn-hover-border-color: #6528e0;
  --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #5a23c8;
  --bs-btn-active-border-color: #5a23c8;
}

.bd-mode-toggle {
  z-index: 1500;
}

.bd-mode-toggle .dropdown-menu .active .bi {
  display: block !important;
}

.bi {
  display: inline-block;
  width: 1rem;
  height: 1rem;
}

/*
* Sidebar
*/

@media (min-width: 992px) {
  .sidebar .offcanvas-lg {
    position: -webkit-sticky;
    position: sticky;
    top: 48px;
  }
  .navbar-search {
    display: block;
  }
}

@media screen and (min-width: 992px) {
  .sidebar {
    min-height: 100vh;
  }
}

.sidebar {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  .nav-link {
    font-size: .875rem;
    font-weight: 500;
    color: black;
    border-radius: 10px;
    padding: 15px;

    &:hover {
      background: $primary-color;
      color: #fff !important;
      border-radius: 10px;
    }

    &.active {
      color: #585f8f;
    }

    .bi {
      margin-top: -3px;
    }
  }
}

.sidebar-heading {
  font-size: .75rem;
}

/*
* Navbar
*/
.navbar-brand {
  padding-top: .75rem;
  padding-bottom: .75rem;
  background-color: rgba(0, 0, 0, .25);
  box-shadow: none;
}

.navbar .form-control {
  padding: .75rem 1rem;
}

.form-group {
  .react-datepicker-wrapper {
    width: 100%;
  }
}


.rbc-calendar {
  min-height: 650px;
}

.main-content {
  background: #EEF2F6;
  //padding-top: 60px;
  @media only screen and (max-width: 992px) {
    padding-top: 10px;
  }
}

.timetracking-toggle-btn {
  //position: fixed;
  //right: 19px;
  cursor: pointer;
  //bottom: 20px;
  z-index: 9999;
  background: $primary-pink-color;
  color: #fff;
  border-radius: 6px;
  border: none;
  //height: 40px;
  //width: 43px;
  font-size: 28px;
  padding-left: 11px;
  padding-top: 4px;
  height: 50px;
  width: 50px;

  i {
    top: -4px;
    position: relative;
  }
}

.timer-widget-wrapper {
  z-index: 999;
  //position: fixed;

  //bottom: 10px;
  //right: 15px;
  border-radius: 7px;
  background: #d4a4bd;
  padding: 10px;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  //width: 500px;
  width: 100%;;
  //opacity: 0.4;

  padding: 7px;
  height: 50px;

  .form-select,
  .form-control {
    border: none;
    background-color: #fff;
    &:hover {
      background-color: #fff;
    }
  }
  .form-select {
    width: 250px;
  }

  //&:hover {
  //  opacity: 1;
  //}
  button {
    width: 38px;
    outline: none;
    border: none;
    background: none;
    font-size: 28px;
    line-height: 13px;
    padding: 0;
    margin: 0;
    margin-right: 10px;
    color: $primary-pink-color;

    &:disabled {
      color: $primary-color;
    }
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
    position: relative;
    margin-bottom: 10px;
  }
}


@keyframes blink {
  0% {
    opacity: 0
  }
  50% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
}

.blinking {
  //animation: 1s blink ease infinite;
  animation: blink 1.5s infinite;
  animation-fill-mode: both;
}

.side-calendar {
  .side-calendar-day-title {
    background: $primary-color;
    padding: 3px;
    border-radius: 4px;
    margin-top: 15px;
    color: #fff;
  }

  .current-date {
    font-size: 20px;
    font-weight: normal;
    padding-left: 0.6rem;
  }

  .event-wrapper {
    padding-top: 10px;

    .event-item {
      //box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      border: 2px solid #FCEDC9;
      border-radius: 8px;
      padding: 10px;
      margin-bottom: 15px;
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;

      .time {
        font-size: 13px;
        background: $primary-color;
        color: #fff;
        padding: 2px 5px;
        border-radius: 4px;
      }

      .info {
        display: flex;
        flex-direction: column;

        .client {
          font-size: 14px;
          font-weight: 500;
        }

        .title {
          font-size: 12px;
          color: #666;
        }
      }
    }
  }

  h5 {
    font-weight: normal !important;
    padding-left: 0.6rem;
    font-size: 12px;
  }

  .react-datepicker {
    //margin-top: 15px;
    width: 100%;
    border: none;
    background: #FDFAF0;
    padding: 10px;
    //margin-top: 10px;

    .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
      border-color: $secondary-color;
      border-width: 2px 2px 0 0;
    }

    .react-datepicker__navigation {
      top: 22px;
    }

    .react-datepicker__navigation--next {
      right: -7px;
    }

    .react-datepicker__navigation--previous {
      right: 15px;
      left: auto;
    }

    .react-datepicker__header {
      background: none;
      border: none;
      text-align: left;
    }

    .react-datepicker__current-month {
      font-size: 20px;
      font-weight: normal;
      padding-left: 5px;
      padding-right: 23px;
    }

    .react-datepicker__month-container {
      width: 100%;
    }

    .react-datepicker__month {
      margin: 0;
    }

    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
      width: calc(100% / 7);
      margin: 0;
    }

    .react-datepicker__day--selected {
      background: $primary-color;
    }

    .react-datepicker__day-name {
      color: $secondary-color
    }
  }
}

.navbar-brand.bg-white {
  width: 150px;
  height: 140px;

  @media only screen and (max-width: 992px) {
    width: auto;
    height: auto;

    > img {
      width: 55px !important;
    }
  }
}

.scroll-margin {
  scroll-margin: 80px;
}
