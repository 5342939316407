@import "../../colors";
$border-color: #b1b4b6;

.form-control.pagination_input_page {
  width: 53px;
  padding-left: 7px;
  padding-right: 2px;
}

ul.pagination {
  li {
    button:disabled {
      color: $border-color;

      &:hover {
        background: inherit;
      }
    }
  }
}

.fndt_sort_link {
  color: #000;
  text-decoration: none;
}

.fndt_pagination_wrapper {
  background: #fff;
  padding: 15px;
  margin-bottom: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  .pagination {
    margin: 0;
  }
}

table.table.fndt_table {

  margin-bottom: 0;
  border-collapse: separate;
  border-spacing: 0;


  td {
    border-style: none solid solid none;
    padding: 10px;

    border-bottom-color: #EEF2F6;
  }

  th {
    border-bottom-color: #EEF2F6;
    padding: 0.8rem;
  }

  tr:first-child th:first-child {
    border-top-left-radius: 10px;
  }

  tr:first-child th:last-child {
    border-top-right-radius: 10px;
  }

  //tr:last-child td:first-child {
  //  border-bottom-left-radius: 10px;
  //}
  //
  //tr:last-child td:last-child {
  //  border-bottom-right-radius: 10px;
  //}

  tr:first-child td {
    border-top-style: solid;
  }

  tr td:first-child {
    border-left-style: solid;
  }


  thead {
    border-bottom: 1px solid $border-color;

    tr {
      th {
        color: $primary-color;
      }
    }
  }

  tbody {
    border-top: none;

    tr {
      border-bottom: 1px solid $border-color;
      //cursor: pointer;

      td {
      }

      &.active {
        //background: lightgoldenrodyellow;
      }

      &:hover {
        td {
          //background-color: #EEF2F6;
        }
      }
    }
  }
}

td.multiple-buttons {
  text-align: right;

  button + button {
    margin-left: 5px;
  }
}
